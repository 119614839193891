/* eslint-disable import/prefer-default-export */
export const optionsChannel = (resource = []) => [
    ...resource?.find((el) => el === 'listPage') ? [
        {
            aclCode: 'oms_v2_channel_store',
            key: 'channelstore',
            label: 'Channel Store',
            url: '/channel_store',
            children: [
            ],
        }] : [],
];
